import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { Typography } from '@mui/material'
import background from '../assets/background_01.jpg'
import AboutUs from './AboutUs'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './heroimg.css';
import Services from './Services'
import ContactUs from './ContactUs'
import Gallery from './Gallery'
import Footer from './Footer'
import Washura from './Washura'
function Home() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div style={{ margin: '0.5rem' }}>
            <Navbar />
            <div className="hero">
                <div>
                    <Typography variant="h3" sx={{ color: 'white', position: 'relative' }}>
                        Refresh Your Wardrobe Today!
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'white', position: 'relative', mt: '1rem' }}>
                        Trust the experts at Kunal's Drycleaners to give your clothes the care they deserve. Try us now!
                    </Typography>
                </div>
            </div>
            <div data-aos="fade-up">
                <AboutUs />
            </div>
            <div data-aos="fade-up">
                <Washura />
                <hr size='3' noshade style={{marginTop:'1rem', width:'70%', backgroundColor:'#000'}} />
            </div>
            
            <div data-aos="fade-up">
                <Services />
                <hr size='3' noshade style={{marginTop:'1rem', width:'70%', backgroundColor:'#000'}} />
            </div>
            
            <div data-aos="fade-up">
                <Gallery />
            </div>
            <Footer />
        </div>
    )
}

export default Home