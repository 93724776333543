import React from 'react'
import dryclean_01 from '../assets/dryclean_01.jpg'
import ironing_01 from '../assets/Ironing_01.jpg'
import laundry_01 from '../assets/laundry_01.jpg'
import { Typography, Box, Grid } from '@mui/material'
function Services() {
    return (
        <>
            <Box sx={{ my: '1.5rem', textAlign: 'center' }}>
                <Typography variant='h4' sx={{mb:'1rem'}}>
                    Our Services
                </Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={4}>
                        <div style={{marginBottom:'1rem'}}>
                            <img style={{ height: '11rem', width: '20rem', borderRadius: '1rem' }} src={dryclean_01} alt="Dryclean" />
                        </div>
                        <Box>
                            <Typography variant='h5'>
                                Dry Cleaning
                            </Typography>
                            <Typography variant='body1'>
                                Expert dry cleaning for your delicate fabrics. Leave it to us to keep your garments looking their best.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <div style={{marginBottom:'1rem'}}>
                            <img style={{ height: '11rem', width: '20rem', borderRadius: '1rem' }} src={ironing_01} alt="Steam Pressing" />
                        </div>
                        <Box>
                            <Typography variant='h5'>
                                Pressing & Steaming
                            </Typography>
                            <Typography variant='body1'>
                                Get your clothes professionally pressed and steamed to remove all wrinkles and ensure a polished appearance.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <div style={{marginBottom:'1rem'}}>
                            <img style={{ height: '11rem', width: '20rem', borderRadius: '1rem' }} src={laundry_01} alt="Laundry Service" />
                        </div>
                        <Box>
                            <Typography variant='h5'>
                                Laundry
                            </Typography>
                            <Typography variant='body1'>
                                Professional laundry services for clothes and other fabric items. We provide high-quality and reliable care for all types of garments.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Services