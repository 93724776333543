import { Box , Typography} from '@mui/material'
import React from 'react'
import Masonry from '@mui/lab/Masonry';
import tshirt from '../Gallery_Assets/tshirt.JPG'
import n_jacket from '../Gallery_Assets/n_jacket.JPG'
import coat_01 from '../Gallery_Assets/coat_01.JPG'
import coat_black from '../Gallery_Assets/coat_black.JPG'
import cream_lahenga from '../Gallery_Assets/cream_lahenga.JPG'
import lahenga_saree_2 from '../Gallery_Assets/lahenga_saree_2.jpg'
import lahenga_saree from '../Gallery_Assets/lahenga_saree.JPG'
import white_lahenga from '../Gallery_Assets/white_lahenga.JPG'
import dress_01 from '../Gallery_Assets/dress_01.JPG'
import dress_02 from '../Gallery_Assets/dress_02.JPG'
import sherwani from '../Gallery_Assets/sherwani.JPG'
import kurte from '../Gallery_Assets/kurte.JPG'
const itemData = [
    {
        img: coat_black,
        title: 'Black Coat',
    },
    {
        img: n_jacket,
        title: 'Nehru Jacket',
    },
    {
        img: dress_01,
        title: 'Dress White',
    },
    {
        img: white_lahenga,
        title: 'White Lahenga',
    },
    {
        img: cream_lahenga,
        title: 'Cream Lahenga',
    },
    {
        img: coat_01,
        title: 'Coat',
    },
    {
        img: dress_02,
        title: 'Dress',
    },
    {
        img: sherwani,
        title: 'Sherwani',
    },
    {
        img: tshirt,
        title: 'T-Shirt',
    },
    {
        img: kurte,
        title: 'Kurte',
    },
    {
        img: lahenga_saree_2,
        title: 'Lahenga Saree',
    },
    {
        img: lahenga_saree,
        title: 'Lahenga Saree',
    }
];

function Gallery() {
    return (
        <div>
            <Box sx={{ my: '1.5rem', textAlign: 'center' }}>
            <Typography variant='h4' sx={{mb:'1rem'}}>
                    Gallery
                </Typography>
            </Box>
            <Box>
                <Masonry columns={3} spacing={2}>
                    {itemData.map((item, index) => (
                        <div key={index}>
                            {/* <Label>{index + 1}</Label> */}
                            <img
                                srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=162&auto=format`}
                                alt={item.title}
                                loading="lazy"
                                style={{
                                    borderRadius:'0.5rem',
                                    display: 'block',
                                    width: '100%',
                                }}
                            />
                        </div>
                    ))}
                </Masonry>
            </Box>
        </div>
    )
}

export default Gallery