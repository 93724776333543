import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import { useEffect, useState } from 'react';
import Spinner from './Components/utils/Spinner';

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])
  return (
    <>
    {
      loading ? (
        <Spinner/>
      ) : (
        <Home/>
      )
    }
    </>
  );
}

export default App;
