import { Typography, Box, Grid } from '@mui/material'
import React from 'react'

function Washura() {
    return (
        <div>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant='h5' style={{ fontStyle: 'italic' }}>
                    "From Our Factory to Your Fabrics: Quality Dry Cleaning with Our Own Detergents"
                </Typography>
                <Typography variant='body1' sx={{ my: '0.5rem' }}>
                    Our laundry detergents, washing powders, and dishwashing products are designed to deliver a level of freshness that's simply enchanting. Say goodbye to stains, odors, and grease, and say hello to a world of immaculate cleanliness. Experience the magic of cleanliness with <b>Washura</b>.
                </Typography>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
                <Grid container spacing={3} sx={{ mt: '1rem' }}>
                    <Grid item sm={12} md={4}>
                        <div style={{ marginBottom: '1rem' }}>
                            <img style={{ height: '11rem', width: '20rem', borderRadius: '1rem' }} src='https://img.freepik.com/free-vector/laundry-detergent-ad-poster_98292-6976.jpg?w=740&t=st=1695886178~exp=1695886778~hmac=d1b87cf06f17399c3be731892b75290dc88facd7996fdb00c2ecaf7e134390ce' alt="Washing Powder" />
                        </div>
                        <Box>
                            <Typography variant='h5'>
                                Washing Powder
                            </Typography>
                            <Typography variant='body1' sx={{ my: '0.5rem' }}>
                                🌸 Revive Your Fabrics: Our advanced formula breathes new life into your clothes, removing even the toughest stains.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <div style={{ marginBottom: '1rem' }}>
                            <img style={{ height: '11rem', width: '20rem', borderRadius: '1rem' }} src='https://img.freepik.com/free-photo/basket-clothes_93675-130521.jpg?w=740&t=st=1695887870~exp=1695888470~hmac=184df4e564232f053925b5220534b93c755c00bfc82f04a7d5407825f1a05518' alt="Laundry Detergent" />
                        </div>
                        <Box>
                            <Typography variant='h5'>
                                Laundry Detergent
                            </Typography>
                            <Typography variant='body1' sx={{ my: '0.5rem' }}>
                                🌼 Gentle Care: While tough on stains, we're gentle on your fabrics, ensuring they last longer. Enjoy the delightful scent that lingers on your clothes.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <div style={{ marginBottom: '1rem' }}>
                            <img style={{ height: '11rem', width: '20rem', borderRadius: '1rem' }} src='https://img.freepik.com/free-vector/realistic-cleaning-products-ad_52683-38276.jpg?w=740&t=st=1695888098~exp=1695888698~hmac=439ae6a53a101acfa605cb1aaf5792af0aa4e17e58c07b04af45c1cce2b3f17e' alt="Dishwash Gel" />
                        </div>
                        <Box>
                            <Typography variant='h5'>
                                Dishwash Gel
                            </Typography>
                            <Typography variant='body1' sx={{ my: '0.5rem' }}>
                                🍽️ Tough on Grease, Gentle on Hands: Our advanced formula cuts through stubborn grease and grime while keeping your hands soft and smooth.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Washura