import React from 'react'
import { Box, AppBar, Typography, Toolbar } from '@mui/material';
import { AiFillInstagram } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import logo from '../Gallery_Assets/kunal_logo_trans.png'
function Navbar() {
    return (
        <div>
            <Box>
                <AppBar sx={{backgroundColor:'#fff', color:'#000'}}>
                    <Toolbar>
                        <Box sx={{display:'flex' , justifyContent:'space-between' ,alignItems: 'center', width:'100%' }}>
                            <div style={{display:'flex',alignItems:'center'}}>
                            <div><img style={{width:'2rem'}} src={logo}/></div>
                            <Typography variant="h5">
                                Kunal's Drycleaners & Laundry
                            </Typography>
                            </div>
                            <Box>
                            <a href="tel:+919826050553" style={{color:'black'}}> <BsFillTelephoneFill/> </a>
                            {/* <AiFillInstagram style={{fontSize:'1.5rem'}}/> */}
                            </Box>
                        </Box>

                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}

export default Navbar