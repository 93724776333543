import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import white_lahenga from '../Gallery_Assets/white_lahenga.JPG'
import lahenga_saree from '../Gallery_Assets/lahenga_saree.JPG'

function AboutUs() {
    return (
        <div>
            <Box sx={{ my: '1.5rem', textAlign: 'center' }}>

                <Grid container>
                    <Grid item sm={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1.2rem' }}>
                        <Box>
                            <Typography variant='h4' sx={{mb:'0.5rem'}}>
                                About Us
                            </Typography>
                            <Typography variant="body1">
                                Kunal's Drycleaners is a trusted name in the drycleaning industry, located in Indore, Madhya Pradesh. With 20 years of experience, we are committed to providing exceptional drycleaning services to our valued customers.
                                <br/>Our team of skilled professionals ensures that your garments are treated with utmost care and attention to detail, delivering impeccable results every time. Whether it's a delicate silk dress or a tailored suit, you can rely on us to restore the freshness and cleanliness of your clothing. 
                                <br/>At Kunal's Drycleaners, we strive for excellence and customer satisfaction, making us the preferred choice for all your drycleaning needs.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img alt="Kunal's Drcleaners" style={{width:'90%',borderRadius:'1rem',margin:'0.5rem'}} src= {lahenga_saree} />
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default AboutUs