import { Grid, Typography, Box, Divider } from '@mui/material'
import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { FaFacebook } from 'react-icons/fa';
import {TiLocation} from 'react-icons/ti'
import { AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai'

const mediaQuery = window.matchMedia('(min-width: 750px)')
export function dynamic(){
    if (mediaQuery.matches) {
        console.log('Media Query Matched!')
        return(<Divider variant="middle"  orientation="vertical" flexItem sx={{backgroundColor:'#fff'}} />)
    }   
}
export function justifyContentValue(){
    if (mediaQuery.matches) {
        return('space-around')        
    }
    else{
        return('space-between')
    }
}
function Footer() {
    
    return (
        <div style={{ backgroundColor: '#474746', color: '#fff', padding:'1.5rem'}}>
            <Grid sd={1} container sx={{justifyContent: justifyContentValue() }}>
                <Grid item sx={{my:'1rem'}}>
                    <Typography variant="h6" sx={{mb:'0.5rem'}}>
                        Kunal's Drycleaners & Laundry
                    </Typography>
                    <a href="tel:+919826050553" style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}> <BsFillTelephoneFill style={{fontSize:'1rem'}}/>
                        <Typography variant='subtitle1' sx={{ ml: '0.5rem' }}>
                            +91 9826050553
                        </Typography> </a>
                    <a href="mailto:kunalsdrycleaners@gmail.com" style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}> <FiMail style={{fontSize:'1rem'}}/>
                        <Typography variant='subtitle1' sx={{ ml: '0.5rem' }}>
                            kunalsdrycleaners@gmail.com
                        </Typography> </a>
                    <Box sx={{ display: 'flex',justifyContent:'space-between',mt:'0.5rem' }}>
                        <a href="https://www.facebook.com/" target='_blank' style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <FaFacebook style={{fontSize:'1.5rem'}} />
                        </a>
                        <a href="https://www.instagram.com/" target='_blank' style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <AiFillInstagram style={{fontSize:'1.5rem'}} />
                        </a>
                        <a href="https://www.linkedin.com/" target='_blank' style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <AiFillLinkedin style={{fontSize:'1.5rem'}} />
                        </a>
                        <a href="https://www.twitter.com/" target='_blank' style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <AiFillTwitterSquare style={{fontSize:'1.5rem'}} />
                        </a>
                    </Box>
                </Grid>
                {/* <Grid item>
                <div style={{height:'100%',backgroundColor:'#fff',width:'0.1rem'}}></div>
                </Grid> */}
                {dynamic()}
                <Grid item sx={{my:'1rem'}}>
                    <Box>
                        <Typography variant="h6" sx={{display: 'flex', alignItems: 'center', mb:'0.5rem'}}>
                            <TiLocation style={{marginRight:'0.5rem',fontSize:'1.5rem'}} /> Vijay Nagar Outlet
                        </Typography>
                        <a href="https://g.co/kgs/hSYXgH/" target='_blank' style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <Typography variant='subtitle1'>
                            LG-30, BCM Heights,<br/>Scheme No.54, Vijay Nagar, <br/>Indore, M.P.
                            </Typography>
                        </a>
                    </Box>
                </Grid>
                {dynamic()}
                {/* <Divider variant="middle" orientation='vertical' flexItem sx={{backgroundColor:'#fff'}} /> */}
                {/* <Grid item>
                <div style={{height:'100%',backgroundColor:'#fff',width:'0.1rem'}}></div>
                </Grid> */}
                <Grid item sx={{my:'1rem'}}>
                    <Box>
                        <Typography variant="h6" sx={{display: 'flex', alignItems: 'center',mb:'0.5rem'}}>
                            <TiLocation style={{marginRight:'0.5rem',fontSize:'1.5rem'}} />Palasia Outlet
                        </Typography>
                        <a href="https://g.co/kgs/U2e3Wp" target='_blank' style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <Typography variant='subtitle1'>
                            UG-8, Palasia Towers, <br/>Near 56 Dukan, New Palasia, <br/>Indore,M.P.
                            </Typography>
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer